import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable , Subject} from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class RentalService {

  constructor(
    private http: HttpClient, 
    private _http: Http,
    private authservce: AuthService,
  ) { }

  getRentalPlans(): Observable<any>{
    
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/rental-plan/list/';
    let body = {};
    console.log(uri);
    return this._http.post(uri, body, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  add(formData): Observable<any> {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/rental-plan/add';
    console.log(uri);
    return this._http.post(uri, formData, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  getRentalPlanById(id): Observable<any>{
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const url = API_URL + '/rental-plan/edit/' + id;
    return this
      .http
      .get(url, options).pipe(
        map(res => {
          console.log(res);
          return res;
        }));  
  }

  updateRentalPlan(formData, id): Observable<any> {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/rental-plan/update/'+ id;
    let body = {};
    console.log(uri);
    return this._http.post(uri, formData, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }
}

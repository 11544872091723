import { CouponDetails, ConfigSetting } from '../interfaces/coupon-details.model'

export class CouponServiceConstants {
  public couponDetails: CouponDetails = {
    title: '',
    description: '',
    code: '',
    active: true,
    start_date:'',
    end_date: '',
    is_lifetime: false,
    users: [],
    applicable_to_all_users: false,
    regions:[],
    applicable_to_all_regions: false,
    categories: [],
    applicable_to_all_categories: false,
    applicable_to_all_first_ride: false,
    no_of_rides_per_user: '',
    discount_type: 'FIXED',
    amount: '',
    image: ''
  };
  public configSetting : ConfigSetting = {
    driver_action: false,
    rider_action: false,
    vehicles_action: false,
    cab_body_type_action: false,
    cab_amenities_action: false,
    cab_category_action: false,
    rides_action: false,
    region_action: false,
    pincode_action: false,
    pickup_drop_action: false,
    manual_dispatcher_action: false,
    payment_setting_action: false,
    user_payment_action: false,
    withdrawal_action: false,
    referral_setting_action: false,
    referral_code_action: false,
    referral_usage_code_action: false,
    coupon_action: false,
    admin_user_action: false,
    admin_role_action: false,
    app_store_action: false,
    social_links: false,
    helps_driver: false,
    helps_user: false,
    faq_driver: false,
    faq_user: false,
    // edit
    driver_edit_action: false,
    rider_edit_action: false,
    vehicles_edit_action: false,
    cab_body_type_edit_action: false,
    cab_amenities_edit_action: false,
    cab_category_edit_action: false,
    rides_edit_action: false,
    region_edit_action: false,
    pincode_edit_action: false,
    pickup_drop_edit_action: false,
    manual_dispatcher_edit_action: false,
    payment_setting_edit_action: false,
    user_payment_edit_action: false,
    withdrawal_edit_action: false,
    referral_setting_edit_action: false,
    referral_code_edit_action: false,
    referral_usage_code_edit_action: false,
    coupon_edit_action: false,
    admin_user_edit_action: false,
    admin_role_edit_action: false,
    app_store_edit_action: false,
    social_links_edit: false,
    helps_driver_edit: false,
    helps_user_edit: false,
    faq_driver_edit: false,
    faq_user_edit: false
  }
}
